import Car from './../components/Car/CarComponent';
import { connect } from "react-redux";
import {authAction, cartAction, vehicleAction} from "./../store/actions";

const mapStateToProps = state => {
    const {
        vehicleReducer: { 
            getProductsSuccess, 
            getProductsError, 
            getProductsLoader,
            getCountriesSuccess,
            getCountriesError,
            getCountriesLoader,
            getSpecificationsSuccess,
            getSpecificationsError,
            getSpecificationsLoader,
            getPriceSuccess,
            getPriceError,
            addProductToBasketSuccess,
            addProductToBasketError,
            addProductToBasketLoader,
            placeOrderSuccess,
            placeOrderError,
            placeOrderLoader,
            getShippingMethodsSuccess,
            getShippingMethodsError,
            getShippingMethodsLoader
      },
      authReducer: {
          getUserBasketSuccess,
          getUserBasketError,
          getUserBasketLoader,
          apiPhoneValidationLoader,
          apiPhoneValidationSuccess,
          apiPhoneValidationError,
          apiPhoneValidationConfirmLoader,
          apiPhoneValidationConfirmSuccess,
          apiPhoneValidationConfirmError
      },
      cartReducer: {
          cartProducts,
          cartProductsReduced
      } // Add cartReducer here
    } = state;
    return {
        getProductsSuccess,
        getProductsError,
        getProductsLoader,
        getCountriesSuccess,
        getCountriesError,
        getCountriesLoader,
        getSpecificationsSuccess,
        getSpecificationsError,
        getSpecificationsLoader,
        getPriceSuccess,
        getPriceError,
        addProductToBasketSuccess,
        addProductToBasketError,
        addProductToBasketLoader,
        placeOrderSuccess,
        placeOrderError,
        placeOrderLoader,
        getUserBasketSuccess,
        getUserBasketError,
        getUserBasketLoader,
        getShippingMethodsSuccess,
        getShippingMethodsError,
        getShippingMethodsLoader,
        apiPhoneValidationLoader,
        apiPhoneValidationSuccess,
        apiPhoneValidationError,
        apiPhoneValidationConfirmLoader,
        apiPhoneValidationConfirmSuccess,
        apiPhoneValidationConfirmError,
        cartProducts, // Expose cartProducts to the component
        cartProductsReduced // Expose cartProductsReduced to the component
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getProducts: payload =>
            dispatch(vehicleAction.getProducts(payload)),
        addProductToBasket: payload =>
            dispatch(vehicleAction.addProductToBasket(payload)),
        placeOrder: payload =>
          dispatch(vehicleAction.placeOrder(payload)),
        getCountries: payload =>
          dispatch(vehicleAction.getCountries()),
        getUserBasket: payload =>
          dispatch(authAction.getUserBasket(payload)),
        clearCart: payload =>
          dispatch(cartAction.clearCart(payload)),
        validatePhone: payload =>
          dispatch(authAction.validatePhone(payload)),
        validatePhoneConfirm: payload =>
          dispatch(authAction.validatePhoneConfirm(payload)),
        getBasketMeta: payload =>
          dispatch(authAction.getBasketMeta(payload))

    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Car);